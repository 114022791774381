import React from 'react';
import Media3 from '../Assets/Media3.png'
import ArticleHeadshot from '../Assets/Article-headshot.png'

export default function MediaAppearance3() {
  

  return (
    <div className='media-card-container'>
      <div className='media-card'>
      <a className='articles' href="https://www.abc.net.au/triplej/programs/hack/gen-z-religion-statistics/104667438" target="_blank" rel="noopener noreferrer"><h3 className='article-heading'>Interview on Triple J Hack: Why are young guys becoming more Christian?</h3>  </a>
        <a className='articles' href="https://www.abc.net.au/triplej/programs/hack/gen-z-religion-statistics/104667438" target="_blank" rel="noopener noreferrer"><img className='articlesimgs' src={Media3} alt="MediaAppearance3" /></a>
        <div className='media-card-container'>
        <img className='article-headshot' src={ArticleHeadshot} alt="article-headshot" />
          <a href="https://theconversation.com/profiles/andrew-thomas-710801" target="_blank" rel="noopener noreferrer"><b className='headshot-name'>Dr Andrew Thomas</b></a>
          <br></br>
          <p className='headshot-title'>Guest on Triple J - Hack</p>
      </div>
      </div>
    </div>
  );
}
