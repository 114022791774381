import React from 'react';
import Article8 from '../Assets/Article8.png' //particle cover image import
import ArticleWriter from './ArticleWriter';

export default function MediaCard8() {
  

  return (
    <div className='media-card-container'>
      <div className='media-card'>
      <a className='articles' href="https://theconversation.com/israel-and-iran-are-playing-a-dangerous-game-of-chicken-that-will-be-impossible-to-contain-forever-242275" target="_blank" rel="noopener noreferrer"><h3 className='article-heading'>Israel and Iran are playing a dangerous game of chicken that will be impossible to contain forever</h3>  </a>
        <p className='article-author'>Author: Thomas, Andrew
        <br></br>
        <em className=''>The Conversation, </em>27 October 2024.
        </p>
        <a className='articles' href="https://theconversation.com/israel-and-iran-are-playing-a-dangerous-game-of-chicken-that-will-be-impossible-to-contain-forever-242275" target="_blank" rel="noopener noreferrer"><img className='articlesimgs' src={Article8} alt="Article 8" /></a>
        <ArticleWriter/>
      </div>
    </div>
  );
}