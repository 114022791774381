import React from 'react';
import Article7 from '../Assets/Article7.png' //particle cover image import
import ArticleWriter from './ArticleWriter';

export default function MediaCard7() {
  

  return (
    <div className='media-card-container'>
      <div className='media-card'>
      <a className='articles' href="https://doi.org/10.20991/allazimuth.1511004" target="_blank" rel="noopener noreferrer"><h3 className='article-heading'>Sanctions and Postcolonial Statecraft in Iran: Resisting the Iran Libya Sanctions Act and Beyond</h3>  </a>
        <p className='article-author'>Author: Thomas, Andrew
        <br></br>
        <em className=''>Dergi Park, </em>16 July 2024.
        </p>
        <a className='articles' href="https://doi.org/10.20991/allazimuth.1511004" target="_blank" rel="noopener noreferrer"><img className='articlesimgs' src={Article7} alt="Article 7" /></a>
        <ArticleWriter/>
      </div>
    </div>
  );
}
